<template>
  <v-container fluid class="px-0">
    <MyDialog ref="itemsDialog" @onConfirm="deleteItem" />
    <MyDataTable
      title="مدیریت درخواست برداشت ها"
      addtitle="تراکنش جدید"
      :addpageRoute="
        `${$route.path}/create${
          $route.query.page ? '?page=' + $route.query.page : ''
        }`
      "
      :headers="headers"
      :items="items"
      :loading="loading"
      :pagesLength="pagesLength"
      @onPageChanged="GetList"
      :isRemoveEnabled="false"
      :isEditEnabled="false"
      :hasButton="false"
      class="mt-n13 moholand-shadow"
    >
      <template #contentTag="{ row }">
        <td>
          {{ row.price ? Intl.NumberFormat().format(row.price) : 0 }} تومان
        </td>
        <td>{{ row.date | moment("jYYYY/jMM/jDD") }}</td>
        <td>
          <v-chip
            v-if="row.status == 0"
            color="yellow darken-3"
            dark
            class="rounded"
            small
          >
            در حال بررسی
          </v-chip>
          <div v-else-if="row.status == 1">
            <v-chip color="success" dark class="rounded" small>
              تایید شده
            </v-chip>
          </div>
          <div v-else>
            <v-chip color="red" dark class="rounded" small>
              رد شده
            </v-chip>
          </div>
        </td>
        <td>
          <div v-if="row.status == 0" />
          <div v-else-if="row.status == 1">
            <v-chip
              color="success"
              dark
              class="rounded mr-3"
              small
              v-if="row.status_desc"
            >
              {{ row.status_desc }}
            </v-chip>
          </div>
          <div v-else>
            <v-chip
              color="red"
              dark
              class="rounded mr-3"
              small
              v-if="row.status_desc"
            >
              {{ row.status_desc }}
            </v-chip>
          </div>
        </td>
      </template>
    </MyDataTable>
  </v-container>
</template>

<script>
import { Constants } from "@/constants/GlobalConstants";
import MyAxios from "@/constants/MyAxios";
import MyDialog from "@/components/MyDialog";
import MyDataTable from "@/components/MyDataTable";

export default {
  created() {
    this.GetList();
  },
  components: {
    MyDialog,
    MyDataTable,
  },
  methods: {
    GetList() {
      if (!this.loading) this.loading = true;

      MyAxios.get("/withdraws/index", {
        params: {
          page: this.$route.query?.page || 1,
          conditions: {
            user_id: this.$store.state.userData?.id,
          },
        },
      })
        .then((response) => {
          this.loading = false;
          this.items = response.data.data;
          this.pagesLength = response.data.last_page;
        })
        .catch((error) => {
          this.loading = false;
        });
    },
    onDetailsClick(item) {
      this.$router.push({
        name: "Sources",
        params: {
          parentId: item.id,
        },
      });
    },
    deleteItem(item) {
      MyAxios.delete(`/withdraws/${item.id}/delete`)
        .then((response) => {
          console.log("delete", response);
          this.$root.$emit("toast", {
            text: "آیتم با موفقیت حذف شد.",
            type: "success",
          });
          this.GetList();
        })
        .catch((error) => {
          console.log("laws", error.response);
        });
    },
    updateItems(items) {
      this.items = items;
    },
  },
  data() {
    return {
      loading: true,
      items: [],
      selectedData: {},
      page: 1,
      pagesLength: 1,
      path: "/withdraws/index",
      pathParams: {
        with: "user",
      },
      headers: [
        { text: "قیمت", value: "price" },
        { text: "تاریخ", value: "date" },
        { text: "وضعیت", value: "status" },
        { text: "توضیحات", value: "status_desc" },
      ],
    };
  },
};
</script>

<style></style>
